import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { MDXLayout as PageLayout } from "../../components/blocks/mdx-layout";
import { SEO } from "../../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="NFTs" description="Besides software development I have another huge passion of mine: Designing UI in Figma, creating photo manipulations in Photoshop, tinkering with abstract art in Cinema4D, and exploring nature & capturing it with my Fujifilm camera. Or in other words: I enjoy art!" breadcrumbListItems={[{
      name: `NFTs`,
      url: `/nfts`
    }]} mdxType="SEO" />

    <h1 {...{
      "id": "non-fungible-token"
    }}>{`Non Fungible Token`}</h1>
    <p>{`Besides software development and management, I have acquired a huge interest in cryptocurrency and blockchain. One application that especially interests me is Non-fungible Token a.k.a NFTs. Currently, all my NFTs are up for display and buy on `}<a parentName="p" {...{
        "href": "https://opensea.io/cryptominder"
      }}>{`Opensea`}</a>{`. They are minted on the polygon network which is usually very cheap to mint on as compared to traditional ethereum networks which rely on huge gas fees. I am planning to write on how to mint your own first NFT in subsequent articles, so stay tuned.`}</p>
    <p>{`Below are a few examples of NFTs I minted and added for sale.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/bd16b04c606a247993caf1c16f5e21f2/dbdff/9.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80.078125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAgX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAA//aAAwDAQACEAMQAAABkfB05UQZD//EABsQAAICAwEAAAAAAAAAAAAAAAECAxEAEjEy/9oACAEBAAEFAlkpYhuXNtzEUlJff//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/ASf/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwFn/8QAHBAAAQQDAQAAAAAAAAAAAAAAAQACECERMUFh/9oACAEBAAY/Ai1teoE2jqAMcj//xAAaEAACAgMAAAAAAAAAAAAAAAAAAREhMUFR/9oACAEBAAE/IbMNwZCVcvZcm1jtk1ESge8cR//aAAwDAQACAAMAAAAQi9//xAAXEQADAQAAAAAAAAAAAAAAAAAAASEx/9oACAEDAQE/EK0cP//EABcRAQEBAQAAAAAAAAAAAAAAAAEAIRH/2gAIAQIBAT8QyZHUv//EAB0QAQEBAQABBQAAAAAAAAAAAAERIQAxQWFxgcH/2gAIAQEAAT8QdCvVmvz7crNWqk3h9b1E2LXPW/vHwalS9KUgQIFXGvOEBgOSIHf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Grandpa and his casio",
            "title": "Grandpa and his casio",
            "src": "/static/bd16b04c606a247993caf1c16f5e21f2/dbdff/9.jpg",
            "srcSet": ["/static/bd16b04c606a247993caf1c16f5e21f2/b95e4/9.jpg 256w", "/static/bd16b04c606a247993caf1c16f5e21f2/1779b/9.jpg 512w", "/static/bd16b04c606a247993caf1c16f5e21f2/dbdff/9.jpg 1000w"],
            "sizes": "(max-width: 1000px) 100vw, 1000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/67c8462235e18b1d401f24b1b376381f/58ee8/nft_1.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.171875%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMFAQT/xAAUAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAGL3znpgsH/xAAaEAEBAAIDAAAAAAAAAAAAAAABAAIREiEx/9oACAEBAAEFAj3kFmayhW7v/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAgIDAAAAAAAAAAAAAAAAACEBMQIQEv/aAAgBAQAGPwJ0PFE81uz/xAAZEAEAAwEBAAAAAAAAAAAAAAABABEhQTH/2gAIAQEAAT8hQkTrYVKM+VyUJLzKRhoLhEtqn//aAAwDAQACAAMAAAAQhC//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPxCn/8QAFREBAQAAAAAAAAAAAAAAAAAAACH/2gAIAQIBAT8QR//EABwQAQACAgMBAAAAAAAAAAAAAAEAETFRQWGR8P/aAAgBAQABPxAZRUpqHMjoAJ89huGbfXcwVD7FBvA7lkIRrM//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Looking back at you",
            "title": "Looking back at you",
            "src": "/static/67c8462235e18b1d401f24b1b376381f/87945/nft_1.jpg",
            "srcSet": ["/static/67c8462235e18b1d401f24b1b376381f/b95e4/nft_1.jpg 256w", "/static/67c8462235e18b1d401f24b1b376381f/1779b/nft_1.jpg 512w", "/static/67c8462235e18b1d401f24b1b376381f/87945/nft_1.jpg 1024w", "/static/67c8462235e18b1d401f24b1b376381f/58ee8/nft_1.jpg 1255w"],
            "sizes": "(max-width: 1024px) 100vw, 1024px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/81b9233939cec6081b222bbac4f90dc0/008f5/4000_4_08.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAMB/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAEEA//aAAwDAQACEAMQAAABrMoiqxrIAwF//8QAGxAAAgEFAAAAAAAAAAAAAAAAAQIRAAMQIDH/2gAIAQEAAQUCPFYnDmKNyRp//8QAFxEBAAMAAAAAAAAAAAAAAAAAAwIRIP/aAAgBAwEBPwEyuGP/xAAWEQEBAQAAAAAAAAAAAAAAAAACESD/2gAIAQIBAT8BSix//8QAGxAAAQQDAAAAAAAAAAAAAAAAEQABAhASIDH/2gAIAQEABj8CTmON8Gv/xAAbEAEBAQEAAwEAAAAAAAAAAAABEQAhEEFhcf/aAAgBAQABPyFRIX5vVt4UE3KAh5+Y6XQtnclI4IQ3/9oADAMBAAIAAwAAABBw97//xAAYEQEBAQEBAAAAAAAAAAAAAAABABExIf/aAAgBAwEBPxDbUNeT5a3b/8QAFxEBAAMAAAAAAAAAAAAAAAAAARARMf/aAAgBAgEBPxBwpzYoj//EABwQAQACAwADAAAAAAAAAAAAAAEAESExURBB4f/aAAgBAQABPxBGBhgbY3ebAFu/uvGLN7TpDAZLg9YbvOoqB7LixYRgeQECx2MAAoME/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "The Dabbing Gamer",
            "title": "The Dabbing Gamer",
            "src": "/static/81b9233939cec6081b222bbac4f90dc0/87945/4000_4_08.jpg",
            "srcSet": ["/static/81b9233939cec6081b222bbac4f90dc0/b95e4/4000_4_08.jpg 256w", "/static/81b9233939cec6081b222bbac4f90dc0/1779b/4000_4_08.jpg 512w", "/static/81b9233939cec6081b222bbac4f90dc0/87945/4000_4_08.jpg 1024w", "/static/81b9233939cec6081b222bbac4f90dc0/6afe2/4000_4_08.jpg 1536w", "/static/81b9233939cec6081b222bbac4f90dc0/7a411/4000_4_08.jpg 2048w", "/static/81b9233939cec6081b222bbac4f90dc0/008f5/4000_4_08.jpg 4000w"],
            "sizes": "(max-width: 1024px) 100vw, 1024px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f805ad9bd7690f93ecd4f25ddf31adcf/9a593/unicorn_cat.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAECAwQF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAIB/9oADAMBAAIQAxAAAAHq0pgyd4JQEg//xAAaEAEAAgMBAAAAAAAAAAAAAAABABECAxAS/9oACAEBAAEFAlqC3NuXkx2C8o5//8QAFhEAAwAAAAAAAAAAAAAAAAAAESAh/9oACAEDAQE/ARE//8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAGhABAAMAAwAAAAAAAAAAAAAAAQAQEQIiMf/aAAgBAQAGPwKdjKJx1V2/Cv/EAB0QAQABBAMBAAAAAAAAAAAAAAERABAhMUFRYYH/2gAIAQEAAT8hATWOyljNtc531QVoLyyCQk0Hovlv/9oADAMBAAIAAwAAABAD8P8A/8QAFhEBAQEAAAAAAAAAAAAAAAAAASAh/9oACAEDAQE/EAaWP//EABYRAQEBAAAAAAAAAAAAAAAAAAERIP/aAAgBAgEBPxCqwMf/xAAdEAEAAQQDAQAAAAAAAAAAAAABEQAhMVEQQbHw/9oACAEBAAE/EJ2l0GV1Vu8QzKL/AD3HCsJzcmAR7elYaKUg9sbaMUiAHIk06LBYQHH/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Unicorn Cat",
            "title": "Unicorn Cat",
            "src": "/static/f805ad9bd7690f93ecd4f25ddf31adcf/87945/unicorn_cat.jpg",
            "srcSet": ["/static/f805ad9bd7690f93ecd4f25ddf31adcf/b95e4/unicorn_cat.jpg 256w", "/static/f805ad9bd7690f93ecd4f25ddf31adcf/1779b/unicorn_cat.jpg 512w", "/static/f805ad9bd7690f93ecd4f25ddf31adcf/87945/unicorn_cat.jpg 1024w", "/static/f805ad9bd7690f93ecd4f25ddf31adcf/6afe2/unicorn_cat.jpg 1536w", "/static/f805ad9bd7690f93ecd4f25ddf31adcf/7a411/unicorn_cat.jpg 2048w", "/static/f805ad9bd7690f93ecd4f25ddf31adcf/9a593/unicorn_cat.jpg 3000w"],
            "sizes": "(max-width: 1024px) 100vw, 1024px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`I mint NFTs for passion and try to help my cause. I have been an active member of Lions International, an NGO doing wholesome good work for people around the world. Recently I contacted another NGO : `}<a parentName="p" {...{
        "href": "https://ashrayakruti.org/"
      }}>{`Ashray Akruti`}</a>{` who is run by a bunch of beautiful souls working for people with disabilities in Education, Health, and Skill Development for the past 25 years. I have decided to donate a portion of my earnings via NFTs to these organizations.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      